import ClearIcon from '@mui/icons-material/Clear';
import { Alert, Backdrop, Box, IconButton, List, ListItem, Snackbar, TextField, Typography } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import SignaturePad from 'react-signature-canvas';
import StyledButton from './common/Button'; // Reuse StyledButton from SignupScreen

const DocHelperModal = ({ open, onClose, onSubmit, userEmail, docId }) => {
  const [signaturePad, setSignaturePad] = useState(null);
  const [email, setEmail] = useState('');
  const [emails, setEmails] = useState([]);
  const [signed, setSigned] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleSignedStatus = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/documents/sign/${docId}?signer_email=${encodeURIComponent(userEmail)}`);
      setSigned(response.data);
    } catch (error) {
      console.error("Error getting signature:", error);
      alert("Error getting signature. Please try again.");
    }
  }, [docId, userEmail]);

  useEffect(() => {
    if (open) {
      handleSignedStatus();
    }
  }, [open, handleSignedStatus]);

  const clearSignature = () => {
    if (signaturePad) signaturePad.clear();
  };

  const handleAddEmail = async () => {
    if (email) {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/documents/invite/`, {
          email: email,
          doc_id: docId
        }, {
          headers: {
            'Authorization': `Bearer ${token}` 
          }
        });

        // If the invite is successful, add the email to the list
        setEmails([...emails, email]);
        setEmail('');
        alert(response.data.message);  // Notify the user about the email being sent
      } catch (error) {
        console.error("Error inviting signer:", error);
        alert("Error inviting signer. Please try again.");
      }
    }
  };

  const handleSubmit = async () => {
    if (signaturePad) {
      const signatureData = signaturePad.toDataURL();
  
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/documents/sign/${docId}?signer_email=${encodeURIComponent(userEmail)}`);
        if (response.status === 200) {
          setSnackbarOpen(true);  // Show the Snackbar
  
          // Delay closing the modal to allow Snackbar to display
          setTimeout(() => {
            onSubmit(signatureData, emails);
            onClose();  // Close the modal after a delay
          }, 1500);  // Adjust the delay time if necessary
        }
      } catch (error) {
        console.error("Error signing document:", error);
        alert("Error signing document. Please try again.");
      }
    }
  };

  if (!open) return null;

  return (
    <>
      <Backdrop
        open={open}
        onClick={onClose}
        sx={{ zIndex: 2, color: '#fff', backdropFilter: 'blur(5px)' }}
      />

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        position="fixed"
        top="5%"
        left="35%"
        transform="translate(-50%, -50%)"
        width="90%"
        maxWidth="500px"
        maxHeight="90vh"
        backgroundColor="rgba(255, 255, 255, 0.9)" // Slightly transparent background
        borderRadius="10px"
        padding="20px"
        boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
        zIndex={1001}       // Ensure it's above the backdrop
        overflow="auto"  // Ensure scrolling if content overflows
      >
        <Typography variant="h6" mb={2}>
          Sign Now
        </Typography>

        <Typography variant="subtitle1" mb={2}>Add Additional Signers</Typography>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Enter email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleAddEmail()}
          sx={{ mb: 2, width: '100%' }}
        />
        <StyledButton variant="contained" onClick={handleAddEmail} sx={{ mb: 2, width: '100%' }}>
          Add Email
        </StyledButton>

        <List sx={{ mb: 2, width: '100%' }}>
          {emails.map((email, index) => (
            <ListItem key={index}>
              {email}
              <IconButton edge="end" aria-label="delete" onClick={() => setEmails(emails.filter((_, i) => i !== index))} sx={{ color: '#ff914d' }}>
                <ClearIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>

        {signed && (
          <Alert severity="info" sx={{ mb: 2 }}>
            You have already signed this document.
          </Alert>
        )}

        {!signed && (
          <>
            <Typography variant="subtitle1" gutterBottom>Your Signature</Typography>
            <SignaturePad
              ref={(ref) => setSignaturePad(ref)}
              canvasProps={{ width: 250, height: 150, className: 'signatureCanvas' }}
              backgroundColor="#d3d3d3"
            />
            <StyledButton onClick={clearSignature} color="secondary" variant="contained" sx={{ mt: 1, mb: 2, width: '100%' }}>
              Clear Signature
            </StyledButton>

            <Box display="flex" justifyContent="space-between" sx={{ width: '100%' }}>
              <StyledButton variant="contained" onClick={handleSubmit} disabled={signed} sx={{ mb: 2, width: '48%' }}>
                Adopt and Sign
              </StyledButton>
              <StyledButton variant="outlined" onClick={onClose} sx={{ mb: 2, width: '48%' }}>
                Cancel
              </StyledButton>
            </Box>
          </>
        )}

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}  // Set how long the Snackbar will be visible (6000ms = 6 seconds)
          onClose={() => setSnackbarOpen(false)}  // Close the Snackbar when the duration expires
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <MuiAlert severity="success" sx={{ width: '100%' }}>
            Document signed successfully!
          </MuiAlert>
        </Snackbar>
      </Box>
    </>
  );
};

export default DocHelperModal;
