import axios from 'axios';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { jwtDecode } from 'jwt-decode';

const storeUserInDatabase = async (user, isFirebaseAuth) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/users/`, { first_name: user.displayName, last_name: "", email: user.email, is_firebase_auth: isFirebaseAuth });
    return response.data.id;
  } catch (error) {
    if (error.response && error.response.status === 409) {
      console.log("User already exists and is verified.");
      return error.response.data.detail.user.id;
    } else {
      console.error("Error storing user in database:", error.message);
      alert("Failed to store user information. Please try again.");
      return null;
    }
  }
};

const handleGoogleLogin = async (auth, { onSuccess }) => {
  const provider = new GoogleAuthProvider();
  try {
    const result = await signInWithPopup(auth, provider);
    const userId = await storeUserInDatabase(result.user, true);
    if (userId) {
      const userWithId = { ...result.user, id: userId, email: result.user.email };
      localStorage.setItem('userId', userId);
      localStorage.setItem('idToken', result._tokenResponse.idToken);
      localStorage.setItem('user', result.user.email);
      localStorage.setItem('email', result.user.email);

      // get user token
      const params = new URLSearchParams();
      params.append('username', result.user.email);
      params.append('password', "firebase-auth");

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/token`, params);

      const token = response.data.access_token;
      localStorage.setItem('token', token);
     
      await onSuccess(userWithId);
    }
    
  } catch (error) {
    console.error("Error logging in with Google:", error);
    alert("Login failed. Please try again.");
  }
};

const handleEmailLogin = async (navigate, email, password, { onSuccess }) => {
  try {
    const params = new URLSearchParams();
    params.append('username', email);
    params.append('password', password);

    const response = await axios.post(`${process.env.REACT_APP_API_URL}/token`, params);

    const token = response.data.access_token;
    localStorage.setItem('token', token);

    // Decode the JWT to get the user information
    const decodedToken = jwtDecode(token);
    const userId = decodedToken.sub;
    localStorage.setItem('userId', userId);
    localStorage.setItem('user', email);
    localStorage.setItem('email', email);
    const userWithId = { user: email, displayName: email, id: userId, email: email };
    await onSuccess(userWithId);
  } catch (error) {
    if (error.response) {
      console.error("Error response:", error.response.data);
      alert(`Login failed: ${error.response.data.detail || "Please try again."}`);
    } else {
      console.error("Error logging in with email and password:", error);
      alert("Login failed. Please try again.");
    }
  } finally {
    navigate('/home');
  }
};

export { handleEmailLogin, handleGoogleLogin, storeUserInDatabase };
