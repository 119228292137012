// PrivateRoute.js
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const PrivateRoute = ({ children, user }) => {
  const location = useLocation();

  if (!user || !user.id) {
    return <Navigate to="/login" state={{ from: location }} />; 
  }
  return children;
};

export default PrivateRoute;
