import { Backdrop, Container } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import { getAuth, signOut } from 'firebase/auth';
import mammoth from 'mammoth';
import React, { useCallback, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import EmailVerification from './EmailVerification';
import HomeScreen from './HomeScreen';
import Landing from './Landing';
import Loading from './Loading';
import LoginScreen from './LoginScreen';
import SignUp from './SignUp';
import UploadScreen from './UploadScreen';
import UserCreate from './UserCreate';
import PrivateRoute from './common/PrivateRoute';

const StyledContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  position: 'relative',
});

const BlurContainer = styled('div')(({ blurred }) => ({
  filter: blurred ? 'blur(5px)' : 'none',
  transition: 'filter 0.3s ease',
  height: '100%',
  width: '100%',
  zIndex: 1,
}));

const ModalWrapper = styled('div')({
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '800px',
  zIndex: 3,
});

const uploadFileToGCS = async (file, preSignedUrl) => {
  try {
    const response = await axios.put(preSignedUrl, file, {
      headers: {
        'Content-Type': file.type
      }
    });
    return response.status === 200;
  } catch (error) {
    console.error('Error uploading to MinIO:', error);
    return false;
  }
};

function Controller() {
  const [file, setFile] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fileRejections, setFileRejections] = useState([]);
  const [user, setUser] = useState(null);
  const [authLoading, setAuthLoading] = useState(true);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [verifiedEmail, setVerifiedEmail] = useState('');

  const location = useLocation();
  const navigate = useNavigate();

  const handleLoginSuccess = async (user) => {
    setUser(user);
  };

  useEffect(() => {
    const initializeUser = () => {
      try {
        const storedUserId = localStorage.getItem('userId');
        const token = localStorage.getItem('token');
        const user = localStorage.getItem('user');
        const email = localStorage.getItem('email');

        if (storedUserId && token) {
          console.log('User ID found in localStorage. Initializing user.');
          setUser({ id: storedUserId, token: token, displayName: user, email: email });
        }
      } catch {
        setUser(null);
      } finally {
        setAuthLoading(false);
      }
    };

    initializeUser();
  }, []);


  useEffect(() => {
    if (!authLoading) {
      if (user && user.id) {
        const allowedPaths = ['/home', '/home/actionflow', '/upload'];
        const documentPathPattern = /^\/home\/document/;

        if (!allowedPaths.includes(location.pathname) && !documentPathPattern.test(location.pathname)) {
          navigate('/home');
        }
      } else if (location.pathname !== '/login' && location.pathname !== '/' && location.pathname !== '/signup' && location.pathname !== '/verify-email' && location.pathname !== '/user-create') {
        console.error('User ID not found in localStorage. Logging out.');
        navigate('/');
      }
    }
  }, [location, navigate, user, authLoading]);

  useEffect(() => {
    if (location.pathname === '/home' && user && user.id && !loading) {
      fetchDocuments(user.id);
    }
  }, [location, user, loading]);

  const handleVerifySuccess = async (email) => {
    setVerifiedEmail(email);
  };

  const handleBackToHome = useCallback(() => {
    setShowUploadModal(false);
    navigate('/home');
  }, [navigate]);

  const fetchDocuments = async (userId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/documents/${userId}`);
      setDocuments(response.data.map(doc => ({
        ...doc,
        id: doc.id,
        uploaded_at: new Date(doc.uploaded_at),
        actions: {},
      })));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setDocuments([]);  // Handle case when no documents are found
      } else {
        console.error('Error fetching documents:', error);
      }
    }
  };

  const processFile = useCallback(async (file) => {
    const newDocument = {
      doc_name: file.name,
      uploaded_at: new Date().toISOString(),
      loading: true,
    };

    setDocuments((prevDocs) => [...prevDocs, newDocument]);
    try {
      const result = await mammoth.extractRawText({ arrayBuffer: await file.arrayBuffer() });
      const text = result.value;
      const uniqueFileName = `${uuidv4()}-${encodeURIComponent(file.name)}`;
      const [summaryResult, actionResult, preSignedUrlResult] = await Promise.all([
        axios.post(`${process.env.REACT_APP_API_URL}/summarize`, { document: text.trim() }),
        axios.post(`${process.env.REACT_APP_API_URL}/actionflow`, { document: text.trim() }),
        axios.post(`${process.env.REACT_APP_API_URL}/generate-presigned-url`, { file_name: uniqueFileName, sign_token: '' })
      ]);

      const uploadSuccess = await uploadFileToGCS(file, preSignedUrlResult.data.url);
      if (!uploadSuccess) throw new Error('Upload to MinIO failed');

      const documentData = {
        bucket_asset_name: uniqueFileName,
        doc_name: file.name,
        doc_summary: summaryResult.data.response,
        owner_id: user.id,
        uploaded_at: new Date().toISOString(),
        actions: actionResult.data.response,
        status: "draft"
      };

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/documents/`, documentData);
      const newDocumentId = response.data.id;

      console.log(response.data);

      setDocuments((prevDocs) => prevDocs.map(doc =>
        doc.doc_name === file.name && doc.uploaded_at === newDocument.uploaded_at
          ? { ...doc, id: newDocumentId, bucket_asset_name: uniqueFileName, doc_summary: summaryResult.data.response, actions: {}, loading: false }
          : doc
      ));
      handleBackToHome();
    } catch (error) {
      console.error('Error processing text:', error);
      alert('Error processing text!');
      setDocuments((prevDocs) => prevDocs.filter(doc => doc.doc_name !== file.name || doc.uploaded_at !== newDocument.uploaded_at));
    } finally {
    }
  }, [user, handleBackToHome]);

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    setFileRejections(rejectedFiles);
    if (acceptedFiles.length === 0) {
      alert('Invalid file type. Please upload a .docx file.');
      return;
    }
    const file = acceptedFiles[0];
    setFile(file);
    setShowUploadModal(false);
    processFile(file);
  }, [processFile]);

  const handleUploadDocument = () => {
    setShowUploadModal(true);
  };

  const handleLogout = async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      localStorage.removeItem('user');
      localStorage.removeItem('email');
      localStorage.removeItem('idToken');
      localStorage.removeItem('actions');
      localStorage.removeItem('selectedAssetId');
      localStorage.removeItem('selectedDocId');
      setUser(null);
      navigate('/home');
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  if (authLoading) {
    return <Loading />;
  }

  return (
    <div className="background-container">
      {loading && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 9999,
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          width: '100vw',
          height: '100vh'
        }}>
          <Loading />
        </div>
      )}
      <BlurContainer blurred={showUploadModal}>
        <StyledContainer>
          <Routes>
            <Route path="/" element={<Landing setLoading={setLoading} />} />
            <Route path="/signup" element={<SignUp setLoading={setLoading} />} />
            <Route path="/verify-email" element={<EmailVerification onVerifySuccess={handleVerifySuccess} />} />
            <Route path="/user-create" element={<UserCreate email={verifiedEmail} onSignupSuccess={handleLoginSuccess} />} />
            <Route path="/login" element={<LoginScreen onLoginSuccess={handleLoginSuccess} setLoading={setLoading} />} />
            <Route path="/home/*" element={
              <PrivateRoute user={user}>
                <HomeScreen
                  documents={documents}
                  setDocuments={setDocuments}
                  onUploadClick={handleUploadDocument}
                  onLogoutClick={handleLogout}
                  onHomeClick={handleBackToHome}
                  user={user}
                />
              </PrivateRoute>
            } />
            <Route path="*" element={<Navigate to="/" />} />

          </Routes>
        </StyledContainer>
      </BlurContainer>
      {showUploadModal && (
        <>
          <Backdrop open={true} style={{ zIndex: 2, color: '#fff', backgroundColor: 'rgba(0, 0, 0, 0.5)' }} onClick={handleBackToHome} />
          <ModalWrapper onClick={(e) => e.stopPropagation()}>
            <UploadScreen
              file={file}
              onDrop={onDrop}
              loading={loading}
              fileRejections={fileRejections}
            />
          </ModalWrapper>
        </>
      )}
    </div>
  );
}

export default Controller;
