import { Box, TextField, Typography } from '@mui/material';
import axios from 'axios'; // Import axios for making API requests
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation after signup
import StyledButton from './common/Button';
import Footer from './Footer';
import { handleEmailLogin } from './Login';

const SignupScreen = ({ email, onSignupSuccess }) => {
  const navigate = useNavigate(); 

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    role: null,
    company: null,
    password: '',
    confirmPassword: '',
  });

  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
  });

  const [isPasswordMatch, setIsPasswordMatch] = useState(true);
  const [isFormComplete, setIsFormComplete] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Set to null if the field is optional and empty
    setFormData({
      ...formData,
      [name]: value === '' && (name === 'role' || name === 'company') ? null : value,
    });

    validateForm({
      ...formData,
      [name]: value === '' && (name === 'role' || name === 'company') ? null : value,
    });
  };

  const validateForm = (updatedFormData) => {
    const { firstName, lastName, password, confirmPassword } = updatedFormData;

    // Update errors
    setErrors({
      firstName: !firstName,
      lastName: !lastName,
    });

    const isComplete =
      firstName && lastName && password && confirmPassword;

    setIsPasswordMatch(password === confirmPassword);
    setIsFormComplete(isComplete && password === confirmPassword && firstName && lastName);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isFormComplete) {
      try {
        const response = await axios.put(`${process.env.REACT_APP_API_URL}/users/`, {
          first_name: formData.firstName,
          last_name: formData.lastName,
          role: formData.role,
          company: formData.company,
          email: email,
          password: formData.password,
        });
  
        console.log('User created:', response.data);
        await handleEmailLogin(navigate, email, formData.password, { onSuccess: onSignupSuccess });
  
        navigate('/home');
      } catch (error) {
        console.error('Error creating user:', error.response?.data || error.message);
        alert('There was an error creating your account. Please try again.');
      }
    }
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <Typography variant="h4" mb={2}>
          Sign Up
        </Typography>
        <TextField
          label="First Name"
          name="firstName"
          variant="outlined"
          value={formData.firstName}
          onChange={handleChange}
          error={errors.firstName}
          helperText={errors.firstName ? 'First name is required' : ''}
          sx={{ mb: 2, width: '300px' }}
        />
        <TextField
          label="Last Name"
          name="lastName"
          variant="outlined"
          value={formData.lastName}
          onChange={handleChange}
          error={errors.lastName}
          helperText={errors.lastName ? 'Last name is required' : ''}
          sx={{ mb: 2, width: '300px' }}
        />
        <TextField
          label="Role (Optional)"
          name="role"
          variant="outlined"
          value={formData.role || ''}
          onChange={handleChange}
          sx={{ mb: 2, width: '300px' }}
        />
        <TextField
          label="Company (Optional)"
          name="company"
          variant="outlined"
          value={formData.company || ''}
          onChange={handleChange}
          sx={{ mb: 2, width: '300px' }}
        />
        <TextField
          label="New Password"
          name="password"
          type="password"
          variant="outlined"
          value={formData.password}
          onChange={handleChange}
          sx={{ mb: 2, width: '300px' }}
        />
        <TextField
          label="Confirm Password"
          name="confirmPassword"
          type="password"
          variant="outlined"
          value={formData.confirmPassword}
          onChange={handleChange}
          sx={{ mb: 2, width: '300px' }}
        />
        {!isPasswordMatch && (
          <Typography variant="body2" color="error" sx={{ mb: 2 }}>
            Passwords do not match.
          </Typography>
        )}
        <StyledButton
          variant="contained"
          disabled={!isFormComplete}
          onClick={handleSubmit}
          sx={{ mb: 2, width: '300px' }}
        >
          Sign Up
        </StyledButton>
      </Box>
      <Footer />
    </>
  );
};

export default SignupScreen;
