import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Chip, IconButton, List, ListItem, ListItemText, Menu, MenuItem, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/system';
import React from 'react';

const SectionHeader = styled(Typography)({
    marginTop: '-10px',
    paddingBottom: '8px',
    paddingTop: '8px',
    borderBottom: '2px solid grey',
    fontWeight: 'bold',
    fontSize: '20px',
    backgroundColor: '#ededed',
    paddingLeft: '16px',
  });
  
const DocumentBox = styled(ListItem, {
shouldForwardProp: (prop) => prop !== 'loading',
})(({ loading }) => ({
backgroundColor: 'transparent',
textAlign: 'left',
display: 'flex',
justifyContent: 'space-between',
alignItems: 'center',
cursor: 'pointer',
transition: 'background-color 0.3s',
'&:hover': {
    backgroundColor: '#ffdd9f',
},
pointerEvents: loading ? 'none' : 'auto',
opacity: loading ? 0.5 : 1,
}));

const DocumentText = styled(ListItemText)({
flex: '1 1 auto',
});

const DocumentActions = styled(Box)({
display: 'flex',
alignItems: 'center',
});

const HomeScreenDocumentListItem = ({ headerName, statusFilter, documents, handleDocumentClick, handleDownload, handleMenuClick, handleMenuClose, handleDelete, formatDate, anchorEl, menuDocIndex }) => {
    return (
        <>
            {/* Uploaded Documents */}
            <SectionHeader variant="h6">{headerName}</SectionHeader>
            <List>
                {documents
                    .filter(statusFilter)
                    .map((doc, index) => (
                        <DocumentBox key={index} onClick={() => handleDocumentClick(doc)} loading={doc.loading}>
                            <DocumentText primary={`${doc.doc_name}`} secondary={formatDate(doc.uploaded_at)} />
                            <Chip label={doc.loading ? "" : doc.doc_summary.split(".")[0]} sx={{ marginRight: '10px' }}></Chip>
                            <DocumentActions>
                                {doc.loading ? (
                                    <CircularProgress sx={{ color: '#ff914d', marginRight: '5px' }} size={24} />
                                ) : (
                                    <CheckCircleIcon color="success" sx={{ marginRight: '5px' }} />
                                )}
                                <IconButton edge="end" aria-label="download" onClick={(event) => handleDownload(event, doc.bucket_asset_name)}>
                                    <CloudDownloadIcon />
                                </IconButton>
                                <IconButton
                                    edge="end"
                                    aria-label="more"
                                    onClick={(event) => handleMenuClick(event, index, doc.id)}
                                    sx={{ marginLeft: 1 }}
                                >
                                    <MoreVertIcon />
                                </IconButton>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl) && menuDocIndex === index}
                                    onClose={handleMenuClose}
                                >
                                    <MenuItem onClick={handleDelete}>Delete</MenuItem>
                                </Menu>
                            </DocumentActions>
                        </DocumentBox>
                    ))}
            </List>
        </>
    );
};

export default HomeScreenDocumentListItem;