import { Box, CircularProgress, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import mammoth from 'mammoth';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import DocHelperModal from './DocHelperModal';

import { Create as CreateIcon } from '@mui/icons-material';

const StyledContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start', // Align items at the start
  height: 'calc(100vh - 112px)', // Adjust height to account for the top container
  padding: '32px',
  overflowY: 'auto', // Ensure content is scrollable
  boxSizing: 'border-box',
  width: '100%', // Ensure the container takes the full width
});

const SignButton = styled(IconButton)({
  position: 'fixed',
  bottom: '20px',
  right: '20px',
  height: '80px',
  width: '80px',
  backgroundColor: '#ff914d',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#ff8a00',
  },
  zIndex: 1000,
});

const DocxViewer = ({ bucketAssetName, userEmail, docId }) => {
  const [loading, setLoading] = useState(true);
  const [docContent, setDocContent] = useState(null);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {documentName} = useParams();

  const location = useLocation();

  useEffect(() => {
    const fetchDocument = async () => {
      setLoading(true);
      try {
        let token = localStorage.getItem('token');
        const fileName = bucketAssetName || encodeURIComponent(documentName);

        const queryParams = new URLSearchParams(location.search);
        const signToken = queryParams.get('signToken') || '';
        

        const response = await axios.post(`${process.env.REACT_APP_API_URL}/generate-download-url`, { file_name: fileName, sign_token: signToken }, {
          headers: {
            'Authorization': `Bearer ${token}` 
          }
        });
        if (response.status === 200) {
          localStorage.setItem('selectedAssetId', fileName);
          localStorage.setItem('selectedDocId', response.data.docId);
        }
        const documentUrl = response.data.url;

        const docResponse = await axios.get(documentUrl, {
          responseType: 'arraybuffer'
        });

        const arrayBuffer = docResponse.data;
        const result = await mammoth.convertToHtml({ arrayBuffer });
        setDocContent(result.value);
      } catch (error) {
        console.error('Error fetching document:', error);
        setError('Failed to load document');
      } finally {
        setLoading(false);
      }
    };

    fetchDocument();
  }, [bucketAssetName, docId, documentName, location]);

  const toggleModal = () => {
    setIsModalOpen((prev) => !prev);
  };


  const handleSignatureSubmit = (signatureData, emails) => {
    // Handle the signature and email submission logic here
    console.log('Signature Data:', signatureData);
    console.log('Emails:', emails);
    // You can send this data to your backend to save the signature and notify the other signers
  };

  return (
    <>
      <StyledContainer>
        {loading ? (
          <CircularProgress sx={{ color: '#ff914d' }} />
        ) : error ? (
          <p>{error}</p>
        ) : docContent ? (
          <div dangerouslySetInnerHTML={{ __html: docContent }} />
        ) : (
          <p>No document available.</p>
        )}

        <SignButton onClick={toggleModal}>
          <CreateIcon />
        </SignButton>

      </StyledContainer>
      <DocHelperModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleSignatureSubmit}
        userEmail={userEmail}
        docId={docId}
      />
    </>
  );
};

export default DocxViewer;
